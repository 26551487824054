/*
 * @Description:
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-07 14:18:31
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-10 16:20:30
 */
import Vue from 'vue'
import VueRouter from 'vue-router'
import user from './modules/user'
import general from './modules/general'
import layout from './modules/layout'
import exception from './modules/exception'
import {
  getAccessToken
} from '@/utils/localStorage'
import store from '@/store/index.js'

/**
 * 解决vue-router3.0以上报错问题
 * @type {{(location: RawLocation): Promise<Route>; (location: RawLocation, onComplete?: Function, onAbort?: ErrorHandler): void}}
 */
// const originalPush = VueRouter.prototype.push;
// VueRouter.prototype.push = function push(location) {
//   return originalPush.call(this, location).catch(err => err);
// };
/**
 * 解决vue-router3.0以上报错问题
 * @type {{(location: RawLocation): Promise<Route>; (location: RawLocation, onComplete?: Function, onAbort?: ErrorHandler): void}}
 */
try {
  const originalPush = VueRouter.prototype.push
  VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
  }
} catch (e) {
}
Vue.use(VueRouter)

const routes = [
  ...general,
  ...user,
  ...layout,
  ...exception,
  { path: '*', redirect: '/error404' }
]

const router = new VueRouter({
  routes,
  mode: 'history'
})
// 路由白名单
export const whiteList = ['error403',
  'error404',
  'error500',
  '/contentUndefined',
  '/user/login',
  '/user/register',
  '/dashboard',
  '/bayareacertification/index',
  '/bayareacertification/caseCenter',
  '/bayareacertification/application',
  '/bayareacertification/query',
  '/bayareacertification/technicalnorm',
  '/privacy',
  '/aboutUs',
  '/userAgreement'
] // no redirect whitelist

// 路由判断登录
router.beforeEach((to, from, next) => {
  next()
  // // 获取 token 确定用户是否登录过
  // const token = getAccessToken()
  //
  // // 判断当前的token是否存在 ； 登录存入的token
  // if (token) {
  //   // 使页面返回顶部
  //   document.documentElement.scrollTop = 0
  //   next()
  // } else {
  //   if (whiteList.indexOf(to.path) !== -1) {
  //     // 在免登录白名单，直接进入
  //     document.documentElement.scrollTop = 0
  //     next()
  //   } else {
  //     store.commit('SET_IS_SHOW_LOGIN', true)
  //     return false
  //   }
  // }
})

export default router
