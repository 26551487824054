import account from './account'

const exception = [{
  path: '/bayareacertification',
  component: () => import('@/components/project/layout/PLayoutBase'),
  redirect: '/bayareacertification/index',
  children: [
    {
      path: '/error500',
      name: 'error500',
      component: () => import('@/views/exception/500.vue')
    },
    {
      path: '/error403',
      name: 'error403',
      component: () => import('@/views/exception/403.vue')
    }, {
      path: '/error404',
      name: 'error404',
      component: () => import('@/views/exception/404.vue')
    },
    {
      path: '/contentUndefined',
      name: 'contentUndefined',
      component: () => import('@/views/exception/contentUndefined.vue')
    },
    ...account]
}]


export default exception
