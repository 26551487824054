import {
  ACCESS_TOKEN
} from '@/store/mutation-types'
import Storage from 'web-storage-cache'
import { encrypt, decrypt } from '@/utils/utils'

const localStorage = new Storage()

/**
 * 设置缓存
 * @param key
 * @param value
 * @param time 超时时间 单位s
 * @returns {*}
 */
export function setLocalStorage(key, value, time) {
  if (time) {
    return localStorage.set(key, value, { exp: time })
  }
  return localStorage.set(key, value)
}

/**
 * 获取缓存
 * @param key
 * @returns {*}
 */
export function getLocalStorage(key) {
  return localStorage.get(key)
}

/**
 * 移除缓存
 * @param key
 * @returns {*}
 */
export function removeLocalStorage(key) {
  return localStorage.delete(key)
}

/**
 * 清除缓存
 * @returns {*}
 */
export function clearLocalStorage() {
  return localStorage.clear()
}

/**
 * 设置 token
 * @param token
 * @returns {*}
 */
export function saveAccessToken(token) {
  return setLocalStorage(ACCESS_TOKEN, token)
}

/**
 * 获取 token
 * @returns {*}
 */
export function getAccessToken() {
  return getLocalStorage(ACCESS_TOKEN)
}

/**
 * 删除 token
 * @returns {*}
 */
export function removeAccessToken() {
  return removeLocalStorage(ACCESS_TOKEN)
}

/**
 * 设置 自动登录user
 * @param loginUser
 * @returns {*}
 */
export function saveLoginUser(loginUser) {
  const loginUserEncrypt = encrypt(JSON.stringify(loginUser))
  return setLocalStorage('LOGIN_USER', loginUserEncrypt)
}

/**
 * 获取 自动登录user
 * @returns {*}
 */
export function getLoginUser() {
  const loginUserEncrypt = getLocalStorage('LOGIN_USER')
  if (!loginUserEncrypt) {
    return ''
  }
  return JSON.parse(decrypt(loginUserEncrypt))
}

/**
 * 删除 用户自动登录数据
 * @returns {*}
 */
export function removeLoginUser() {
  return removeLocalStorage('LOGIN_USER')
}

/**
 * 缓存 用户信息
 * @param userInfo
 * @returns {*}
 */
export function saveUserInfo(userInfo) {
  return setLocalStorage('USERINFO', userInfo)
}

/**
 * 获取 用户信息
 * @returns {*}
 */
export function getUserInfo() {
  return getLocalStorage('USERINFO')
}

/**
 * 删除 用户信息
 * @returns {*}
 */
export function removeUserInfo() {
  return removeLocalStorage('USERINFO')
}

