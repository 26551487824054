/**
 * 重写elementUI message
 */

import { Message } from 'element-ui'
// 定义一个新的Message方法，多传入一个offset参数
export const $message = options => {
  return Message({
    ...options
  })
};

// 重写方法,将offset写入options
['success', 'warning', 'info', 'error'].forEach(type => {
  $message[type] = options => {
    if (typeof options === 'string') {
      options = {
        message: options,
        offset: 100
      }
    }
    options.type = type
    return Message(options)
  }
})
