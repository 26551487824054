import CryptoJS from 'crypto-js'
import store from '@/store'

/**
  * Created by webstorm
  * User: 小博
  * Date: 2020/3/28
  * Time: 14:58
  * describe: 工具方法
  */
// 盐
const saltFigure = 'JIHuih=23g8byu=56UGB=Y566G=1DF8Y4=CV1G=ty8rty=hfggh=2fghd7=hfgop'

/**
 * 加密
 * @param data
 * @returns {string}
 */
export function encrypt(data) {
  return CryptoJS.AES.encrypt(
    data, saltFigure
  ).toString()
}

/**
 * 解密
 * @return {string}
 */
export function decrypt(str) {
  return CryptoJS.AES.decrypt(
    str, saltFigure
  ).toString(CryptoJS.enc.Utf8)
}

/**
 * uuid
 * @returns {string}
 */
export function uuid() {
  var s = []
  var hexDigits = '0123456789abcdef'
  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
  }
  // bits 12-15 of the time_hi_and_version field to 0010
  s[14] = '4'
  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)
  // s[ 8 ] = s[ 13 ] = s[ 18 ] = s[ 23 ] = '-';
  return s.join('')
}

/**
 * 根据某个属性查找菜单
 * @param key 关键字
 * @param property 参数依据
 * @returns {*}
 */
export function findMenuByProperty(key, property) {
  const menuList = store.getters.permissionList
  for (let i = 0; i < menuList.length; i++) {
    if (key === menuList[i][property]) {
      return menuList[i]
    }
    if (menuList[i].subMenuList) {
      const subMenuList = menuList[i].subMenuList
      for (let j = 0; j < subMenuList.length; j++) {
        if (key === subMenuList[j][property]) {
          return subMenuList[j]
        }
      }
    }
  }
};

/**
 * 字段验证
 * 使用： 手机号 validate('1597xxx7364', 'phone');
 * @param value 需要验证的字符串
 * @param type  验证类型
 *              { 'require':'是否必须', 'phone':'手机号验证','email':'邮箱验证' }
 * @returns {boolean}
 */
export function validate(value, type) {
  // 非空验证
  if (type === 'require') {
    // !!value --  !! 将后面内容强转为 boolean
    // !!0, !!'', !!null, !!undefined, !!NaN -- false
    return !!value
  }
  // 手机号验证
  if (type === 'phone') {
    return /^1\d{10}$/.test(value)
  }
  // 邮箱验证
  if (type === 'email') {
    return /^\w+((.\w+)|(-\w+))@[A-Za-z0-9]+((.|-)[A-Za-z0-9]+).[A-Za-z0-9]+$/.test(value)
  }
};

/**
 * 隐私数据用 * 号进行处理
 * 使用： stringHideStart('15970007364','phone')
 * @param str   需要验证的字符串
 * @param type  验证类型
 *              { 'phone':'手机号', 'idCard':'身份证','bankCard':'银行卡' }
 * @returns {void | string | never|string}
 */
export function stringHideStart(str, type) {
  if (type === 'phone') {
    return str.replace(/(\d{3})\d{4}(\d{4})/, '$1****$2')
  }

  if (type === 'idCard') {
    return str.replace(/^(.{4})(?:\d+)(.{4})$/, '$1******$2')
  }

  if (type === 'bankCard') {
    return str.substr(0, 4) + '********' + str.substr(-4)
  }
}

/**
 * 全屏显示
 */
export function showFullScreen() {
  if (!document.fullscreenElement && !document.mozFullScreenElement && !document.webkitFullscreenElement && !document.msFullscreenElement) {
    if (document.documentElement.requestFullscreen) {
      document.documentElement.requestFullscreen()
    } else if (document.documentElement.msRequestFullscreen) {
      document.documentElement.msRequestFullscreen()
    } else if (document.documentElement.mozRequestFullScreen) {
      document.documentElement.mozRequestFullScreen()
    } else if (document.documentElement.webkitRequestFullscreen) {
      document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT)
    }
  }
}

/**
 * 关闭全屏
 */
export function exitFullScreen() {
  if (document.exitFullscreen) {
    document.exitFullscreen()
  } else if (document.msExitFullscreen) {
    document.msExitFullscreen()
  } else if (document.mozCancelFullScreen) {
    document.mozCancelFullScreen()
  } else if (document.webkitExitFullscreen) {
    document.webkitExitFullscreen()
  }
}

/**
 * 在外部以以新的独立窗口打开网页,用于打开考试界面
 * @param parm
 */
export function windowopen(parm) {
  var config = [
    'fullscreen = yes',
    'location = no',
    'resizable = no',
    'status = no',
    'titlebar = no',
    'toolbar = no'
  ]
  const newwindow = window.open(parm.path, parm.name || '', config.toString())
  newwindow.moveTo(0, 0)
  newwindow.resizeTo(screen.width, screen.height)
}

/**
 *  降序(排序时使用的排序规则)
 * @param basis
 * @returns {function(*, *): number}
 */
function comparaDesc(basis) {
  return (firstobj, secondobj) => {
    if (!basis) {
      // 降序
      return secondobj < firstobj ? -1 : 1
    }
    const firstValue = firstobj[basis]
    const secondValue = secondobj[basis]
    // 降序
    return secondValue < firstValue ? -1 : 1
  }
}

/**
 * 升序(排序时使用的排序规则)
 * @param basis
 * @returns {function(*, *): number}
 */
function comparaAsc(basis) {
  return (firstobj, secondobj) => {
    if (!basis) {
      // 升序
      return firstobj < secondobj ? -1 : 1
    }
    const firstValue = firstobj[basis]
    const secondValue = secondobj[basis]
    // 升序
    return firstValue < secondValue ? -1 : 1
  }
}

/**
 * 列表数组排序
 * @param array 原数组
 * @param basis 依据对象中的某个字段,如果数组元素不是对象,则传 ''
 * @param isDesc 是否降序 bool值
 * @returns {*|void}
 */
export function listCompara(array, basis, isDesc) {
  if (isDesc) {
    // 降序
    return array.sort(comparaDesc(basis))
  }
  // 升序
  return array.sort(comparaAsc(basis))
}

/**
 * 防抖
 * @param {function} fn 执行函数
 * @param {number} delay 等待时间，毫秒
 */
export function debounce(fn, delay) {
  let timer = null
  return function () {
    let content = this
    let args = arguments
    if (timer) {
      clearTimeout(timer)
    }
    timer = setTimeout(() => {
      console.log(222)
      fn.apply(content, args)
    }, delay)
  }
}


/**
 * 节流
 *
 * 节流函数，规定在一个单位时间内，只能触发一次函数。如果这个单位时间内触发多次函数，只有一次生效。
 * 如果100ms内再次执行函数，就重新开始计时，直到计时结束后再真正执行函数。
 * 这样做的好处是如果短时间内大量触发同一事件，只会执行一次函数。
 *
 * @param fun 要节流的函数
 * @param delay 节流的毫秒数
 * @returns {Function}
 */
export function throttle(fun, delay) {
  // 上一次执行该函数的时间
  let lastTime = 0
  return function (...args) {
    // 当前时间
    let now = +new Date()
    // 将当前时间和上一次执行函数时间对比
    // 如果差值大于设置的等待时间就执行函数
    if (now - lastTime > delay) {
      lastTime = now
      console.log(111)
      fun.apply(this, args)
    }
  }
}

/**
 * 随机生成uuid
 * @return string 生成的uuid
 */
export function randomUUID() {
  let chats = '0123456789abcdef'
  return randomString(32, chats)
}

/**
 * 随机生成字符串
 * @param length 字符串的长度
 * @param chats 可选字符串区间（只会生成传入的字符串中的字符）
 * @return string 生成的字符串
 */
export function randomString(length, chats) {
  if (!length) length = 1
  if (!chats) chats = '0123456789qwertyuioplkjhgfdsazxcvbnm'
  let str = ''
  for (let i = 0; i < length; i++) {
    let num = randomNumber(0, chats.length - 1)
    str += chats[num]
  }
  return str
}

/**
 * 随机生成数字
 *
 * 示例：生成长度为 12 的随机数：randomNumber(12)
 * 示例：生成 3~23 之间的随机数：randomNumber(3, 23)
 *
 * @param1 最小值 | 长度
 * @param2 最大值
 * @return int 生成后的数字
 */
export function randomNumber() {
  // 生成 最小值 到 最大值 区间的随机数
  const random = (min, max) => {
    return Math.floor(Math.random() * (max - min + 1) + min)
  }
  if (arguments.length === 1) {
    let [length] = arguments
    // 生成指定长度的随机数字，首位一定不是 0
    let nums = [...Array(length).keys()].map((i) => (i > 0 ? random(0, 9) : random(1, 9)))
    return parseInt(nums.join(''))
  } else if (arguments.length >= 2) {
    let [min, max] = arguments
    return random(min, max)
  } else {
    return Number.NaN
  }
}
