import Vue from 'vue'
import axios from 'axios'
import { getAccessToken, getLocalStorage, removeAccessToken } from '@/utils/localStorage'
import { ACCESS_TOKEN } from '@/store/mutation-types'
import store from '@/store/index.js'
import { TOKEN_KEY } from '@api/tokenKey'
import { BASE_URL, DOMIAN_URL } from '@/config'

let alertObj = null
// 创建实例
const requestObj = axios.create({
  baseURL: DOMIAN_URL,
  timeout: 900000 // 请求超时时间
})

// 请求一些微服务基本的接口
const requestBaseObj = axios.create({
  baseURL: BASE_URL,
  timeout: 900000 // 请求超时时间
})
// 创建实例
const requestObjMock = axios.create({
  // baseURL: process.env.VUE_APP_API_URL
})

// 请求拦截器
requestObj.interceptors.request.use(config => {
  return config
}, err => {
  // 请求错误一般http状态码以4开头，常见：401超时，404 not found
  return Promise.reject(err)
})

requestBaseObj.interceptors.request.use(config => {
  return config
}, err => {
  // 请求错误一般http状态码以4开头，常见：401超时，404 not found
  return Promise.reject(err)
})

const err = error => {
  // 响应错误处理一般http状态码以5开头，常见：500系统错误，502系统重启
  if (error.response) {
    let data = error.response.data
    const token = getLocalStorage(ACCESS_TOKEN)
    console.log('------异常响应------', token)
    console.log('------异常响应------', error.response.status)
    switch (error.response.status) {
    case 401:
      loginOverdue()
      break
    case 500:
      let type = error.response.request.responseType
      if (type === 'blob') {
        blobToJson(data)
        break
      }
      if (token && data.message.includes('Token失效')) {
        loginOverdue()
      }
      break
    }
  } else if (error.message) {
    if (error.message.includes('timeout')) {
      Vue.prototype.$message.error('网络超时!')
    } else {
      Vue.prototype.$message.error(error.message)
    }
  }
  return Promise.reject(error)
}

// 响应拦截器
requestObj.interceptors.response.use(res => {
  return res
}, err)

requestBaseObj.interceptors.response.use(res => {
  return res
}, err)

/**
 * 登录超时提示框
 */
function loginOverdue() {
  alertObj = Vue.prototype.$alert('很抱歉，登录已过期，请重新登录', '登录已过期', {
    confirmButtonText: '重新登录',
    type: 'error',
    showClose: false,
    callback: () => {
      removeAccessToken()
      store.commit('SET_TOKEN', '')
      store.commit('SET_IS_SHOW_LOGIN', true)
    }
  })
}

export function request(settings) {
  let headers = {}
  if (settings.verifyToken) {
    headers = {
      [TOKEN_KEY]: getAccessToken(),
      'Content-Type': settings.isForm ? 'application/x-www-form-urlencoded' : 'application/json; charset=utf-8'
    }
  } else {
    headers = {
      'Content-Type': settings.isForm ? 'application/x-www-form-urlencoded' : 'application/json; charset=utf-8'
    }
  }
  return new Promise((resolve, reject) => {
    requestObj({
      url: settings.url || '',
      method: settings.method || 'get',
      headers,
      data: JSON.stringify(settings.data || {}),
      params: settings.params || {},
      responseType: settings.responseType || 'json'
    }).then((response) => {
      alertObj = null
      resolve(response.data)
    }).catch(error => {
      reject(new Error(error))
    })
  })
}

export function baseRequest(settings) {
  let headers = {}
  if (settings.verifyToken) {
    headers = {
      [TOKEN_KEY]: getAccessToken(),
      'Content-Type': settings.isForm ? 'application/x-www-form-urlencoded' : 'application/json; charset=utf-8'
    }
  } else {
    headers = {
      'Content-Type': settings.isForm ? 'application/x-www-form-urlencoded' : 'application/json; charset=utf-8'
    }
  }
  return new Promise((resolve, reject) => {
    requestBaseObj({
      url: settings.url || '',
      method: settings.method || 'get',
      headers,
      data: JSON.stringify(settings.data || {}),
      params: settings.params || {},
      responseType: settings.responseType || 'json'
    }).then((response) => {
      alertObj = null
      resolve(response.data)
    }).catch(error => {
      reject(new Error(error))
    })
  })
}

export function requestMock(settings) {
  let headers = {}
  if (settings.verifyToken) {
    headers = {
      'token': getAccessToken(),
      'Content-Type': 'application/json; charset=utf-8'
    }
  } else {
    headers = {
      'Content-Type': 'application/json; charset=utf-8'
    }
  }
  return new Promise((resolve, reject) => {
    requestObjMock({
      url: settings.url || '',
      method: settings.method || 'get',
      headers,
      data: JSON.stringify(settings.data || {}),
      params: settings.params || {},
      responseType: settings.responseType || 'json'
    }).then((response) => {
      resolve(response.data)
    })
  })
}

/**
 * Blob解析
 * @param data
 */
function blobToJson(data) {
  let fileReader = new FileReader()
  let token = getLocalStorage(ACCESS_TOKEN)
  fileReader.onload = function () {
    try {
      let jsonData = JSON.parse(this.result) // 说明是普通对象数据，后台转换失败
      console.log('jsonData', jsonData)
      if (jsonData.status === 500) {
        console.log('token----------》', token)
        if (token && jsonData.message.includes('Token失效')) {
          loginOverdue()
        }
      }
    } catch (err) {
      // 解析成对象失败，说明是正常的文件流
      console.log('blob解析fileReader返回err', err)
    }
  }
  fileReader.readAsText(data)
}
