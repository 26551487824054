/*
 * @Description:
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-07 14:18:31
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-07 14:25:54
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI, { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './config'
import store from './store'
import dataV from '@jiaminghi/data-view'
import project from '@/components/project/index'
import EasyProject from 'easy-project-vue'
import '@/assets/fonts/PingFang.css'
import '@/assets/styles/global.less'
import '@/components/iconfont/iconfont.css'
import '@/components/iconfont/iconfont.js'
import '@/permission' // permission control

import '@/utils/rem'

import { $message } from '@/utils/message'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { getLocalStorage } from '@/utils/localStorage'

// 按需引入antDesignVue部分组件
import { Icon, Carousel } from 'ant-design-vue'
import 'ant-design-vue/lib/icon/style/css'
import 'ant-design-vue/lib/carousel/style/css'

// 引入繁简体切换
import zh_tran from '@/assets/lang/lang'
import OpenCC from 'opencc-js'

import VideoPlayer from 'vue-video-player/src'
import 'vue-video-player/src/custom-theme.css'
import 'video.js/dist/video-js.css'

Vue.use(VideoPlayer)

Vue.config.productionTip = false
Vue.use(Icon)
Vue.use(Carousel)

// 挂在全局登录页面
Vue.use(ElementUI)
Vue.use(EasyProject)
Vue.use(dataV)
Vue.use(project)

// 将$message挂载到this上
Vue.prototype.$message = $message
Vue.prototype.$zh_tran = zh_tran
Vue.prototype.$OpenCC = OpenCC
// 不加这行代码运行this.$message.closeAll时会报错
Vue.prototype.$message.closeAll = Message.closeAll

Vue.config.productionTip = false


new Vue({
  router,
  store,
  mounted() {
    store.commit('SET_TOKEN', getLocalStorage(ACCESS_TOKEN))
    store.commit('SET_USER_INFO', getLocalStorage(USER_INFO))
  },
  render: h => h(App)
}).$mount('#app')

