<!--
 * @Description:
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-07 14:18:31
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-12 15:05:19
-->
<template>
  <div>
    <div id="app">
      <router-view></router-view>
    </div>
    <login ref="login"/>
  </div>
</template>
<script>
  import Login from '@/views/user/Login.vue'

  export default {
    components: { Login },
    watch: {
      // 解决vue跳转界面滚动位置记录bug
      '$route': function (to, from) {
        document.body.scrollTop = 0
        document.documentElement.scrollTop = 0
      },
      '$store.state.user.isShowLogin': function (val) {
        if (val) {
          this.$nextTick(() => {
            // this.$refs.login.show()
          })
        }
      }
    }
  }
</script>
<style>
  #app {
    position: relative;
  }
</style>
