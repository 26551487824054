/*
 * @Description: user路由配置
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-09 19:59:14
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-10 16:23:55
 */
const user = [{
  path: '/user',
  component: () => import('@/views/user/index.vue'),
  redirect: '/user/login',
  children: [{
    path: 'login',
    name: 'login',
    component: () => import('@/views/user/Login.vue')
  }, {
    path: 'register',
    name: 'register',
    component: () => import('@/views/user/Register.vue')
  }]
}

]

export default user
