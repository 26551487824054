<!--
 * @Description: 登录
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-09 20:00:08
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-10 15:58:55
-->
<template>
  <el-dialog
    title="登录"
    :close-on-click-modal="false"
    width="384px"
    :before-close="close">
    <div class="loginBox">
      <div class="loginContent">
        <account-login
          ref="accountNumLogin"
          @hasLogin="close"
          :accountForm="accountForm"
          :loading-show="loadingShow"></account-login>
      </div>
    </div>

    <!--    <span slot="footer" class="dialog-footer">-->
    <!--    <el-button @click="close">取 消</el-button>-->
    <!--    <el-button type="primary" @click="close">确 定</el-button>-->
    <!--    </span>-->
  </el-dialog>
</template>

<script>
  import AccountLogin from './AccountLogin'

  export default {
    name: 'Login',
    components: { AccountLogin },
    computed: {
      visible: {
        get() {
          return this.$store.state.user.isShowLogin
        },
        set(value) {
          this.$store.commit('SET_IS_SHOW_LOGIN', value)
        }
      }
    },
    data() {
      return {
        accountForm: {
          accountNum: '',
          password: '',
          captcha_key: ''
        },
        loadingShow: false
      }
    },
    methods: {
      show() {
        window.location.href = process.env.VUE_APP_LOGIN_URL
        // this.visible = true
        // this.$nextTick(() => {
        //   this.$refs.accountNumLogin.handleChangeCheckCode()
        // })
      },
      close() {
        this.visible = false
        this.$refs.accountNumLogin.resetForm()
      }
    }
  }
</script>

<style lang="less" scoped>
  /deep/ .el-dialog {
    border-radius: 10px;
  }

  /deep/ .el-dialog__header {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100px;
    padding: 20px 20px 10px 20px;
    margin: 0 auto;
  }

  /deep/ .el-dialog__title {
    font-size: 22px;
  }

  /deep/ .el-dialog__body {
    padding: 10px 20px;
  }

</style>
