<!--
 * @Description: 登录
 * @Version: 1.0
 * @Autor: DDD
 * @Date: 2021-07-12 20:00:08
 * @LastEditors: WangFan
 * @LastEditTime: 2021-07-25 11:55:34
-->
<template>
  <div class="accountLogin">
    <!--      登录表单区域-->
    <el-form
      ref="accountLogin"
      :model="accountForm"
      :rules="accountFormRules"
    >
      <!--        用户名-->
      <el-form-item prop="username">
        <el-input
          v-model="accountForm.username"
          prefix-icon="el-icon-user-solid"
          type="text"
          placeholder="请输入帐户名"
          clearable
          class="input-message"
        ></el-input>
      </el-form-item>
      <!--        密码-->
      <el-form-item prop="password">
        <el-input
          v-model="accountForm.password"
          prefix-icon="el-icon-lock"
          type="password"
          placeholder="请输入密码"
          clearable
        ></el-input>
      </el-form-item>
      <!--      验证码-->
      <el-form-item label="" prop="inputCode">
        <el-input
          class="code_input"
          v-model="accountForm.inputCode"
          placeholder="验证码"
          prefix-icon="el-icon-document-checked"
          clearable
          @keyup.enter.native="handleSubmit"
        ></el-input>
        <div class="captcha_code">
          <i class="el-icon-loading" v-show="loadingShow"></i>
          <img
            class="code_img"
            v-if="requestCodeSuccess"
            :src="randCodeImage"
            @click="handleChangeCheckCode"/>
          <img
            v-else
            style="margin-top: 2px"
            src="../../assets/checkcode.png"
            @click="handleChangeCheckCode"/>
        </div>
      </el-form-item>
      <!--        登录按钮-->

      <el-form-item style="margin-top: 50px">
        <el-button :loading="loginLoading" type="primary" style="width: 100%;" @click="handleSubmit">登录</el-button>
      </el-form-item>
      <!--        注册和忘记密码-->
      <div class="agree">
        <span>登录即同意
          <router-link to="/privacy" class="user-paper" target="_blank">《用户协议》</router-link>
        </span>
        <!--        <el-button type="text" @click="open" class="fogetPassword">忘记密码？</el-button>-->
      </div>
    </el-form>
  </div>
</template>

<script>
  import Vue from 'vue'
  import { putAction, postAction, getAction } from '@/api/manage'
  import { ACCESS_TOKEN } from '@/store/mutation-types'
  // 引入缓存的方法
  import { clearLocalStorage } from '@/utils/localStorage'
  import { mapActions } from 'vuex'

  export default {
    name: 'AccountLogin',
    data() {
      return {
        loginLoading: false,
        customActiveKey: 'tab1',
        loadingShow: false,
        randCodeImage: '',
        requestCodeSuccess: false,
        uniqueKey: '',
        accountForm: {
          username: '',
          password: '',
          inputCode: ''
        },
        accountFormRules: {
          username: [
            {
              required: true,
              message: '请输入用户名!',
              trigger: 'blur'
            }
          ],
          // 验证密码
          password: [
            {
              required: true,
              message: '请输入登录密码!',
              trigger: 'blur'
            },
            {
              min: 6,
              max: 15,
              message: '长度在 6 到 15 个字符',
              trigger: 'blur'
            }
          ],
          // 验证码
          inputCode: [
            {
              required: true,
              message: '请输入验证码!',
              trigger: 'blur'
            }
          ]
        }
      }
    },
    created() {
      clearLocalStorage(ACCESS_TOKEN)
      // 外部弹窗调用验证码请求方法
      this.handleChangeCheckCode()
    },
    methods: {
      ...mapActions(['Login']),
      open() {
        this.$alert('1.利用账号密码或者手机号验证码登录' +
          '进入首页后将鼠标移动到右上角头像选择修改密码' +
          '       2.进入个人中心点击编辑个人资料进入页面修改！', '修改密码步骤', {
          confirmButtonText: '我已知晓',
          confirmButtonClass: 'ConfirmButtom'
        })
      },
      /**
       * 账号密码登录
       *
       */
      handleSubmit() {
        let that = this
        let loginParams = {}
        that.loginLoading = true
        // 使用账户密码登录

        this.$refs.accountLogin.validate(valid => {
          if (!valid) {
            that.loginLoading = false
            this.handleChangeCheckCode()
            return
          }
          loginParams.username = that.accountForm.username
          loginParams.password = that.accountForm.password
          loginParams.captcha = that.accountForm.inputCode
          loginParams.checkKey = that.uniqueKey
          console.log('登录参数', loginParams)
          that.Login(loginParams).then((res) => {
            that.$emit('hasLogin', true)
            that.loginLoading = false
            location.reload()
          // that.$message.success('登录成功！' + loginParams.username)
          }).catch((res) => {
            that.loginLoading = false
            that.$message.error(`登录失败！${res.message}`)
            that.requestFailed()
          })
        })
      },

      /**
       * 登录所用的error
       */

      submitError(code) {
        if (code === 'A0201') {
          this.$message({
            message: '该用户不存在，请注册',
            type: 'error',
            center: true
          })
        } else if (code === 'A0202') {
          this.$message({
            message: '该用户已被冻结',
            type: 'error',
            center: true
          })
        } else if (code === 'A0203') {
          this.$message({
            message: '该用户已注销',
            type: 'error',
            center: true
          })
        } else if (code === 'A0210') {
          this.$message({
            message: '密码错误，请重试',
            type: 'error',
            center: true
          })
        } else if (code === 'A0400') {
          this.$message({
            message: '手机号格式异常，请输入正确的手机号',
            type: 'error',
            center: true
          })
        } else if (code === 'A0500') {
          this.$message({
            message: '服务器异常！',
            type: 'error',
            center: true
          })
        } else {
          this.$message({
            message: '登录失败，请重试',
            type: 'error',
            center: true
          })
        }
      },
      handleChangeCheckCode() {
        getAction(`/api/v1/login/randomImage`)
          .then((res) => {
            if (res.success) {
              this.accountForm.inputCode = ''
              this.uniqueKey = res.result.uniqueKey
              this.randCodeImage = res.result.randomImage
              this.requestCodeSuccess = true
            } else {
              this.$message.error(res.message)
              this.requestCodeSuccess = false
            }
          })
          .catch(() => {
            this.requestCodeSuccess = false
          })
      },
      requestFailed() {
        this.handleChangeCheckCode()
      },

      resetForm(formName) {
        this.$refs.accountLogin.resetFields()
      }
    }
  }
</script>

<style scoped lang="less">
.fogetPassword {
  float: right;
  color: #000000 !important;
  background: none !important;
  font-size: 14px !important;
  border: none !important;
}

.fogetPassword:hover {
  color: @primary-color !important;
}

.accountLogin {
  padding: 10px 20px;

  .code_input {
    float: left;
    width: calc(50% - 0px);
  }

  .captcha_code {
    right: 0;
    position: absolute;

    .code_img {
      width: 120px;
      height: 40px;
      border: 1px solid rgba(155, 155, 155, 0.5);
      border-radius: 5px;
    }
  }

  .agree {
    display: flex;
    align-items: center;
    justify-content: center;
    //margin-top: 60px;

    .user-paper {
      color: @primary-color;


    }

    .user-paper:hover {
      cursor: pointer;
      opacity: .7;
    }
  }
}

/deep/ .el-input-item {
  .el-input {
    width: 100px !important;
  }

  /deep/ .el-input {
    background: #f3f3f3 !important;
  }

  /deep/ .el-input__inner,
  /deep/ .el-textarea__inner {
    width: 200px;

  }
}

/deep/ .el-input__inner {
  height: 40px !important;
  background-color: #f3f3f3 !important;
  border-radius: 10px;
}

/deep/ .el-input__icon {
  line-height: 40px !important;
}

/deep/ .el-form-item__content {
  display: flex;
  align-items: center;
}

/deep/ .el-button--primary {
  background-color: @primary-color !important;
  border-radius: 10px;
  font-size: 18px;
  color: #fff;
  border: 0;
}

/deep/ .el-checkbox__input.is-checked + .el-checkbox__label {
  color: @primary-color !important;
}

/deep/ .el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate
.el-checkbox__inner {
  background-color: @primary-color !important;
  border-color: @primary-color !important;
}

/deep/ .el-input__inner:focus {
  border-color: @primary-color;
}

/deep/ .el-button--primary:focus, .el-button--primary:hover {
  background: @primary-color;
  border-color: @primary-color;
  color: #fff;
  opacity: 0.85;
}

/deep/ .el-checkbox__label {
  font-size: 12px;
}

/deep/ .el-link.el-link--default:hover {
  color: @primary-color;
}

/deep/ .el-form-item__error {
  margin-left: 0.5vw;
}

.quekily_login img:hover {
  opacity: 0.85;
}

/deep/ .el-message-box__wrapper .el-message-box .el-button--primary {
  display: block;
  background-color: @primary-color !important;
  border-color: @primary-color;
}

/deep/ .el-icon-close:before {
  color: @primary-color;
}

/deep/ .el-input__inner {
  height: 50px;
}

/deep/ .el-input__icon {
  font-size: 16px;
  line-height: 50px;
}
</style>
