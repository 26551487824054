/*
 * @Description: general常规路由配置
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-09 19:59:14
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-10 15:51:44
 */
const general = [
  {
    path: '/dashboard',
    component: () => import('@/views/general/Home.vue')
  }
]

export default general
