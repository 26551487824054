<template>
  <svg class="svg-icon" aria-hidden="true">
    <use :xlink:href="svgIconName"></use>
  </svg>
</template>

<script>
  export default {
    name: 'PIcon',
    props: {
      icon: {
        type: String,
        default: ''
      }
    },
    computed: {
      svgIconName() {
        return `#${this.icon}`
      }
    }
  }
</script>

<style lang="less" scoped>
  .svg-icon {
    vertical-align: -0.15em;
    fill: currentColor;
    overflow: hidden;
  }
</style>
