import { request } from '@/utils/axios'

/**
 * login func
 * parameter: {
 *     username: '',
 *     password: '',
 *     remember_me: true,
 *     captcha: '12345'
 * }
 * @param parameter
 * @returns {*}
 */
export function login(parameter) {
  return request({
    url: '/api/v1/login/login',
    method: 'post',
    data: parameter
  })
}

export function phoneLogin(parameter) {
  return request({
    url: '/sys/phoneLogin',
    method: 'post',
    data: parameter
  })
}

// export function getInfo() {
//   return request({
//     url: '/api/user/info',
//     method: 'get',
//     headers: {
//       'Content-Type': 'application/json;charset=UTF-8'
//     }
//   })
// }

export function logout() {
  return request({
    url: '/api/v1/login/logout',
    method: 'post',
    verifyToken: true
  })
}

/**
 * 第三方登录
 * @param token
 * @param thirdType
 * @returns {*}
 */
export function thirdLogin(token, thirdType) {
  return request({
    url: `/sys/thirdLogin/getLoginUser/${token}/${thirdType}`,
    method: 'get',
    headers: {
      'Content-Type': 'application/json;charset=UTF-8'
    }
  })
}
