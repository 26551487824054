import Vue from 'vue'
import router from '@/router'
import { getLocalStorage, setLocalStorage } from '@/utils/localStorage'
import { ACCESS_TOKEN, USER_INFO } from '@/store/mutation-types'
import { getBaseUserInfo } from '@api/baseApi'
import store from './store'

router.beforeEach((to, from, next) => {
  let tokenType = to.query.tokenType
  let accessToken = to.query.accessToken
  console.log('routeTo', to)

  if (tokenType && accessToken) {
    setLocalStorage(ACCESS_TOKEN, tokenType + ' ' + accessToken)
    // 请求用户信息
    getBaseUserInfo()
      .then(res => {
        if (res.principal) {
          let userInfo = res.principal
          userInfo.realname = res.principal.loginName
          userInfo.id = res.principal.userId
          store.commit('SET_USER_INFO', userInfo)
          store.commit('SET_AVATAR', res.principal.avatar)
          store.commit('SET_USER_INFO', userInfo)
          setLocalStorage(USER_INFO, userInfo)
        }
      })
      .catch(error => {
        console.log('error', error)
        Vue.prototype.$error('操作信息提示，获取用户名失败！')
      }).finally(() => {
      // 过滤掉后面的token参数，其他参数正常传
        let query = JSON.parse(JSON.stringify(to.query))
        delete query.tokenType
        delete query.accessToken
        next({ path: to.path, query: { ...query } })
      })

    return
  }


  next()
})
