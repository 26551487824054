import Vue from 'vue'
import { login, logout, phoneLogin, thirdLogin } from '@/api/login'
import {
  ACCESS_TOKEN,
  USER_INFO
} from '@/store/mutation-types'
import { getAction } from '@/api/manage'
import { removeLocalStorage, setLocalStorage } from '@/utils/localStorage'

const user = {
  state: {
    // 是否展示登录弹窗
    isShowLogin: false,
    token: '',
    username: '',
    realname: '',
    tenantid: '',
    // 用户头像
    avatar: '',
    permissionList: [],
    info: {}
  },

  mutations: {
    SET_IS_SHOW_LOGIN: (state, val) => {
      state.isShowLogin = val
    },
    SET_TOKEN: (state, token) => {
      Vue.set(state, 'token', token)
    },
    SET_USER_INFO: (state, info) => {
      if (!info) {
        return
      }
      state.username = info.username || ''
      state.realname = info.realname || ''
      state.avatar = info.avatar || ''
      state.info = info
    },
    SET_AVATAR: (state, avatar) => {
      state.avatar = avatar
    },
    SET_PERMISSIONLIST: (state, permissionList) => {
      state.permissionList = permissionList
    },
    SET_INFO: (state, info) => {
      state.info = info
    },
    SET_TENANT: (state, id) => {
      state.tenantid = id
    }
  },

  actions: {
    // CAS验证登录
    ValidateLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        getAction('/sys/cas/client/validateLogin', userInfo).then(response => {
          console.log('----cas 登录--------', response)
          if (response.success) {
            const result = response.result
            const userInfo = result.userInfo
            setLocalStorage(ACCESS_TOKEN, result.token)
            setLocalStorage(USER_INFO, userInfo)
            commit('SET_TOKEN', result.token)
            commit('SET_USER_INFO', userInfo)
            resolve(response)
          } else {
            resolve(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登录
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo).then(response => {
          if (response.code == '200') {
            const result = response.result
            const userInfo = result.userInfo
            setLocalStorage(ACCESS_TOKEN, result.token)
            setLocalStorage(USER_INFO, userInfo)
            commit('SET_TOKEN', result.token)
            commit('SET_USER_INFO', userInfo)
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 手机号登录
    PhoneLogin({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        phoneLogin(userInfo).then(response => {
          if (response.code == '200') {
            const result = response.result
            const userInfo = result.userInfo
            setLocalStorage(ACCESS_TOKEN, result.token)
            setLocalStorage(USER_INFO, userInfo)
            commit('SET_TOKEN', result.token)
            commit('SET_USER_INFO', userInfo)
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // 登出
    Logout({ commit, state }) {
      return new Promise((resolve) => {
        commit('SET_TOKEN', '')
        commit('SET_PERMISSIONLIST', [])
        removeLocalStorage(ACCESS_TOKEN)
        removeLocalStorage(USER_INFO)
        resolve()
        // TODO 等对方提供登出接口后再说
        // logout().then(() => {
        //   if (process.env.VUE_APP_SSO == 'true') {
        //     let sevice = 'http://' + window.location.host + '/'
        //     let serviceUrl = encodeURIComponent(sevice)
        //     window.location.href = 'http://19.50.95.194/#/login'
        //   }
        //   commit('SET_TOKEN', '')
        //   commit('SET_PERMISSIONLIST', [])
        //   removeLocalStorage(ACCESS_TOKEN)
        //   removeLocalStorage(USER_INFO)
        //   resolve()
        // }).catch(() => {
        //   resolve()
        // })
      })
    },
    // 第三方登录
    ThirdLogin({ commit }, param) {
      return new Promise((resolve, reject) => {
        thirdLogin(param.token, param.thirdType).then(response => {
          if (response.code == '200') {
            const result = response.result
            const userInfo = result.userInfo
            setLocalStorage(ACCESS_TOKEN, result.token)
            setLocalStorage(USER_INFO, userInfo)
            commit('SET_TOKEN', result.token)
            commit('SET_USER_INFO', userInfo)
            resolve(response)
          } else {
            reject(response)
          }
        }).catch(error => {
          reject(error)
        })
      })
    }


  }
}

export default user
