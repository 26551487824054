export const ACCESS_TOKEN = 'Access-Token'
export const USER_INFO = 'Login_Userinfo'
export const COURSE_CATEGORY = 'Course_Category'
export const DIFFICULTY = 'Difficulty'
//友情链接
export const FRIENDLYLINKS = 'FRIENDLYLINKS'
//网站配置
export const WEBSITE_INFO = 'WEBSITE_INFO'
export const RECORD_NUMBER = 'RECORD_NUMBER'
export const INDEX_MAIN_PAGE_PATH = '/bayareacertification/index'
// 路由缓存问题，关闭了tab页时再打开就不刷新 #842
