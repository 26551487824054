/*
 * @Description: account路由配置
 * @Version: 1.0
 * @Autor: hekai
 * @Date: 2021-07-09 19:59:14
 * @LastEditors: hekai
 * @LastEditTime: 2021-07-10 16:23:55
 */
const account = [{
  path: 'account/course',
  name: 'account-course',
  component: () => import('@/views/account/course/AccountCourse.vue')
}, {
  path: 'account-collect',
  name: 'account-collect',
  component: () => import('@/views/account/collect/AccountCollect.vue')
}, {
  path: 'account/base',
  name: 'account-base',
  component: () => import('@/views/account/base/AccountBase.vue')
}]


export default account
