/**
 * 获取用户信息
 * @param
 * @returns {*}
 */
import { baseRequest } from '@/utils/axios'

export function getBaseUserInfo(params) {
  return baseRequest({
    url: '/auth/user',
    method: 'get',
    params: params,
    verifyToken: true
  })
}

/**
 * 获取所有用户
 * @param
 * @returns {*}
 */
export function getUserPage(params) {
  return baseRequest({
    url: '/system/user',
    method: 'get',
    params: params,
    verifyToken: true
  })
}

/**
 * 获取所有用户根据ids
 * @param
 * @returns {*}
 */
export function getAllUserByIds(params) {
  return baseRequest({
    url: '/system/user/userInfos',
    method: 'get',
    params: params,
    verifyToken: true
  })
}

/**
 * 获取部门列表（分页）
 * @param
 * @returns {*}
 */
export function getUnitList(params) {
  return baseRequest({
    url: '/system/unit/list',
    method: 'get',
    params: params,
    verifyToken: true
  })
}
